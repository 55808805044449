.widget {
    overflow: hidden;
    position: absolute;

    height: 75vh;
    width: 75vh;
    top: calc(50vh - (75vh/2));
    right: calc(50vw - (75vh/2));

    background: linear-gradient(0deg, rgb(67 83 173 / 80%), #5c2be2);
    border-radius: 10px;
    border: #2f1675;
    border-style: solid;
}

.screen-cover {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba(34, 34, 34, 0.671);
}

/*
    STYLES FOR INSIDE THE WIDGET
*/

.widget-navbar {
    background-color: #0000007a;
}

.exit-widget {
    margin-top: 2px;
    display: flex;
    height: 100%;
    position: relative;
    float: inline-end;
    justify-content: center;
    align-content: center;
}

.exit-button {
    fill: whitesmoke;
    height: 30px;
    width: 30px;
}

.title {
    text-align: center;
    padding: 10px 0px 10px 0px;
}

.subject-image {
    float: inline-end;
    height: 320px;
    width: 320px;
}

.content-container>p:first-of-type {
   margin-top: 0;
   padding-top: 15px; 
}

@media screen and (max-width: 1115px){
    .proj_greet {
        text-align: center;
    }
}

@media screen and (max-width: 750px){
    .proj_greet{
        text-align: center;
    }
}

@media screen and (max-width: 500px){

}