.greeting-section>.name {
    font-weight: 700;
    font-size: 140px;
    line-height: 120px;
}

.greeting-section>.start {
    font-weight: 275;
    font-size: 20px;
    line-height: 30px;
}

.greeting-section>.introduction {
    font-weight: 300;
    font-size: 14px;
    line-height: 34px;
}

.greeting-section>.connect {
    font-weight: 150;
    font-size: 14px;
    line-height: 42px;
}

.github-image {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    padding: 0px;
    gap: 20px;
    width: 100%;
    height: 28px;

    /*background: #FFFFFF;*/
}

a {
    border-radius: 50%;
    width: 28px;
    height: 28px;
}

.gitimage {
    transition: 400ms;
    height: 100%;
    width: 28px;

    background: url(../../resources/github.svg);
    border-radius: 118px;
    border: 3px solid #1B1F23;
    border-radius: 39px;
    background-color: white;
    background-position-x: center;
    background-position-y: center;
}

.gitimage {
    background: url(../../resources/github.svg);
}

.linkdimage {
    background: url(../../resources/linkdin.svg);
}


.github-image> a> svg {
    transition: 400ms;
    width: 28px;
    height: 100%;
    border: 3px solid #1B1F23;

    border-radius: 118px;
    
    background-color: white;
    background-position-x: center;
    background-position-y: center;
}

.gitimage:hover{
    transition: ease-out 400ms;
    filter: brightness(.5);
}

.linkdimage:hover{
    transition: ease-out 400ms;
    filter: brightness(.5);
}

.img-container {
    width: 362px;
    height: 362px;
    position: absolute;
    display: flex;
    top: var(--top);
    right: var(--right);
}

.skill-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*
    Keyframes to animate the rotation around the picture
*/

@keyframes rot-1 {
    from {
      -ms-transform: rotate(var(--tdeg));
      -moz-transform: rotate(var(--tdeg));
      -webkit-transform: rotate(var(--tdeg));
      -o-transform: rotate(var(--tdeg));
      transform: rotate(var(--tdeg));
    }
    to {
      -ms-transform: rotate(var(--edeg));
      -moz-transform: rotate(var(--edeg));
      -webkit-transform: rotate(var(--edeg));
      -o-transform: rotate(var(--edeg));
      transform: rotate(var(--edeg));
    }
  }

@-webkit-keyframes rot-1{
    from{
        -webkit-transform: rotate(var(--tdeg));
        -o-transform: rotate(var(--tdeg));
        transform: rotate(var(--tdeg));
    }
    to {
        -webkit-tranform: rotate(var(--edeg));
        -o-transform: rotate(var(--edeg));
        transform: rotate(var(--edeg));
    }
}

.rot-1 {
    width: 500px;
    height: 500px;
    top: calc(var(--top) - 70.5px);
    right: calc(var(--right) - 70.5px);
    -webkit-animation: rot-1 20s linear infinite;
    -moz-animation: rot-1 20s linear infinite;
    -ms-animation: rot-1 20s linear infinite;
    -o-animation: rot-1 20s linear infinite;
    animation: rot-1 20s linear infinite;

    --tdeg: calc(var(--deg) * 45deg);
    --edeg: calc(var(--tdeg) + 360deg);
    --rtdeg: calc(var(--tdeg) * -1);
    --redeg: calc(var(--edeg) * -1);
}

/*
    Keyframes to animate the rotation of the skill badges
    this prevents the images from being presented at the wrong angle
*/

@keyframes rev-1 {
    from {
      -ms-transform: rotate(var(--rtdeg));
      -moz-transform: rotate(var(--rtdeg));
      -webkit-transform: rotate(var(--rtdeg));
      -o-transform: rotate(var(--rtdeg));
      transform: rotate(var(--rtdeg));
    }
    to {
      -ms-transform: rotate(var(--redeg));
      -moz-transform: rotate(var(--redeg));
      -webkit-transform: rotate(var(--redeg));
      -o-transform: rotate(var(--redeg));
      transform: rotate(var(--redeg));
    }
  }

@-webkit-keyframes rev-1{
    from{
        -webkit-transform: rotate(var(--rtdeg));
        -o-transform: rotate(var(--rtdeg));
        transform: rotate(var(--rtdeg));
    }
    to {
        -webkit-tranform: rotate(var(--redeg));
        -o-transform: rotate(var(--redeg));
        transform: rotate(var(--redeg));
    }
}

.rev-1 {
    -webkit-animation: rev-1 20s linear infinite;
    -moz-animation: rev-1 20s linear infinite;
    -ms-animation: rev-1 20s linear infinite;
    -o-animation: rev-1 20s linear infinite;
    animation: rev-1 20s linear infinite;
}

.self-image-port {
    background: url(../../resources/studying.png);
    background-position: center;
    background-size: contain;
    height: 362px;
    width: 362px;
}

.my-skill-logos {
    position: relative;
    width: 75px;
    height: 75px;
    background-size: 75px 75px;
    background-clip: border-box;
    border-radius: 50px;
}

.react {
    background: url(../../resources/React-logo.svg);
    background-size: 75px 75px;
}

.js {
    background: url(../../resources/JS-logo.svg);
    background-size: 75px 75px;
}

.C {
    background: url(../../resources/C-logo.svg);
    background-size: 75px 75px;
}

.DB {
    background: url(../../resources/Database-logo.svg);
    background-size: 75px 75px;
}

.Git {
    background: url(../../resources/Git-logo.svg);
    background-size: 75px 75px;
}

.AWS {
    background: url(../../resources/AWS-logo.svg);
    background-size: 75px 75px;
}

.Java {
    background: url(../../resources/java-logo.svg);
    background-size: 75px 75px;
}

.GCP {
    background: url(../../resources/GCP-logo.svg);
    background-size: 75px 75px;
}

.spacer {
    display: none;
}

@media screen and (max-width: 1115px){
    .spacer {
        display:block;
        height: 500px;
    }

    .rot-1, .rot-2, .rot-3, .rot-4, .img-container{
        margin-left: auto;
        margin-right: auto;
        right: 0;
        left: 0;
    }
}

@media screen and (max-width: 750px) {
    .greeting-section>.name {
        font-weight: 700;
        font-size: 87px;
        line-height: 70px;
    }
    
    .greeting-section>.start {
        font-weight: 275;
        font-size: 15px;
        line-height: 30px;
    }
    
    .greeting-section>.introduction {
        font-weight: 300;
        font-size: 11px;
        line-height: 34px;
    }
    
    .greeting-section>.connect {
        font-weight: 150;
        font-size: 11px;
        line-height: 42px;
    }
}

@media screen and (max-width: 500px) {
    .img-container {
        align-items: center;
        justify-content: center;
        height: 200px;
        width: 200px;
    }

    .self-image-port {
        height: 200px;
        width: 200px;
        background-position: center;
    }

    .rot-1{
        height: 300px;
        width: 300px;
        top: calc(var(--top) - 50px);
    }

    .my-skill-logos {
        width: 50px;
        height: 50px;
        background-size: 50px 50px;
        background-clip: border-box;
        border-radius: 50px;
    }

    .spacer {
        height: 300px;
    }
}
