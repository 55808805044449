body {
  margin: 0;
  color: #FCFCFC;

  font-family: 'Kanit', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #01001A;
  padding-bottom: 15px;
}

body::-webkit-scrollbar {
  width: 10px;
  right: 3%;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  
  border-radius: 30px;
  background-color: #01A4FF;
  outline: 1px solid rgb(18, 114, 211);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  overflow-x: hidden;
  --top: 218PX;
  --right: calc(100vw / 3);
}

@media screen and (max-width: 1500px) {
  :root{
    --right: calc(100vw/6)
  }
}

@media screen and (max-width: 1115px) {
  :root{
    --top: 625px;
  }
}

@media screen and (max-width: 750px) {
  :root{
    --top: 550px;
  }
}


